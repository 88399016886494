import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import AdjustableBaseModelViewerWrapper from '../../src/AdjustableBaseModelViewerWrapper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "adjustablebasemodelviewerwrapper"
    }}>{`AdjustableBaseModelViewerWrapper`}</h1>
    <p>{`This component has some 3rd party dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add three three-stdlib @react-three/fiber @react-three/drei @react-spring/three lottie-react use-long-press
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import AdjustableBaseModelViewerWrapper from '@mfrm/mfcl/AdjustableBaseModelViewerWrapper'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Shows a remote controlled 3D model of an adjustable base.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={AdjustableBaseModelViewerWrapper} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Component initialState={{}}>\n  {({ setState, state }) => (\n    <div style={{ width: \'100%\', minHeight: 500 }}>\n      <AdjustableBaseModelViewerWrapper\n        model={{\n          url:\n            \'https://stproductxrayimgs.blob.core.windows.net/imgs/adjustable-bases-models-gz/MF_900_Update_1/MF900.fbx\',\n          animations: {\n            head: \'armature|Head_Up\',\n            feet: \'armature|Feet_Up\',\n            lumbar: \'armature|Lumbar_Up\',\n          },\n          materials: {\n            M_0_3_AO: \'M_0_3_AO.png\',\n            M_0_3_BaseColor: \'M_0_3_Base_Color.png\',\n            M_0_3_Metalness: \'M_0_3_Metallic.png\',\n            M_0_3_Normal: \'M_0_3_Normal.png\',\n            M_0_3_Roughness: \'M_0_3_Roughness.png\',\n          },\n        }}\n        features={{\n          head: true,\n          feet: true,\n          lumbar: true,\n          flat: true,\n          zeroGravity: true,\n          lights: true,\n          massage: 4,\n        }}\n      />\n    </div>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      AdjustableBaseModelViewerWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{}} mdxType="Component">
    {({
          setState,
          state
        }) => <div style={{
          width: '100%',
          minHeight: 500
        }}>
        <AdjustableBaseModelViewerWrapper model={{
            url: 'https://stproductxrayimgs.blob.core.windows.net/imgs/adjustable-bases-models-gz/MF_900_Update_1/MF900.fbx',
            animations: {
              head: 'armature|Head_Up',
              feet: 'armature|Feet_Up',
              lumbar: 'armature|Lumbar_Up'
            },
            materials: {
              M_0_3_AO: 'M_0_3_AO.png',
              M_0_3_BaseColor: 'M_0_3_Base_Color.png',
              M_0_3_Metalness: 'M_0_3_Metallic.png',
              M_0_3_Normal: 'M_0_3_Normal.png',
              M_0_3_Roughness: 'M_0_3_Roughness.png'
            }
          }} features={{
            head: true,
            feet: true,
            lumbar: true,
            flat: true,
            zeroGravity: true,
            lights: true,
            massage: 4
          }} mdxType="AdjustableBaseModelViewerWrapper" />
      </div>}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      